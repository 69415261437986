import axios from 'axios';
import { Modal, Message } from 'view-ui-plus';
import { baseURL, timeout } from '@/config';

const service = axios.create({
    baseURL,
    timeout
});

service.interceptors.response.use(
    (response) => {
        const resData = response.data;
        if (!String(resData.code).startsWith('20')) {
            Modal.error({
                title: '错误',
                content: `<p>错误信息：${resData.reason || '未知错误'}</p>`
            });
            return Promise.reject(new Error(resData.msg || 'Unknown error'));
        } else {
            return resData;
        }
    },
    (error) => {
        Message.error({
            background: true,
            content: `错误信息：${error.message}`
        });
        return Promise.reject(error);
    }
);

export default service;
