<template>
  <Layout id="layout" class="sans_font">
    <Row justify="center">
      <Col :xs="20" :sm="18" :md="16" :lg="14" :xl="12" :xxl="10" style="max-width: 512px;">
      <Content class="layout-content">
        <Card id="form_card" shadow>
          <template #title> 短链接生成 </template>
          <Form :model="formData" :label-width="70">
            <FormItem label="原链接">
              <Input v-model="formData.longLink" placeholder="请输入待缩短的链接"></Input>
            </FormItem>
            <FormItem label="有效期">
              <Select v-model="formData.lifeTime" placeholder="请选择短链接有效期">
                <Option :value="0">长期</Option>
                <Option :value="1">1 天</Option>
                <Option :value="7">1 周</Option>
                <Option :value="30">1 月</Option>
                <Option :value="365">1 年</Option>
              </Select>
            </FormItem>
            <FormItem label="人机验证">
              <div id="captcha_wrapper">
                <SkeletonItem id="captcha_skeleton" v-if="showSkeleton" animated />
                <vue-hcaptcha ref="hCaptcha" size="compact" :sitekey="siteKey" @rendered="onRendered" @verify="onVerify"
                  @expired="onExpire" @challenge-expired="onChallengeExpire" @error="onError" />
              </div>
            </FormItem>
            <FormItem>
              <Row justify="space-between" :gutter="10">
                <Col :span="12">
                <Button style="width: 100%" type="primary" :loading="loadingStatus" @click="onSubmit">
                  <span v-if="!loadingStatus">
                    <Icon type="md-link" /> 生成
                  </span>
                  <span class="btn_text" v-else>生成中...</span>
                </Button>
                </Col>
                <Col :span="12">
                <Button style="width: 100%" @click="onReset">
                  <Icon type="md-refresh" /> 重置
                </Button>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Card>
        <transition name="ease">
          <Card id="result_card" v-show="shortLink !== null" shadow>
            <template #title> 结果 </template>
            <Spin size="large" fix :show="loadingStatus"></Spin>
            <div id="short-link_container" :class="resultStyle" @click="copyURL(shortLink)">
              <p class="mono_font">{{ shortLink }}</p>
            </div>
          </Card>
        </transition>
      </Content>
      </Col>
    </Row>
    <Row justify="center">
      <Col :xs="20" :sm="18" :md="16" :lg="14" :xl="12" :xxl="10" style="max-width: 512px;">
      <Footer class="layout-footer">
        <span> Built with
          <Icon type="md-heart" color="#ff4500" />
        </span>
        <span>{{ new Date().getFullYear() }} &copy; <span class="mono_font">l2s.cc</span></span>
      </Footer>
      </Col>
    </Row>
  </Layout>
</template>

<script setup>
// Import dependency
import { ref, reactive } from 'vue';
import { siteKey } from '@/config';
import { addLinkApi } from '@/apis/link';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import { Modal, Message } from 'view-ui-plus';

// Reactivity init: Form data & Status
const shortLink = ref(null);
const resultStyle = ref('normal_style');
const loadingStatus = ref(false);
const showSkeleton = ref(true);
const formData = reactive({
  longLink: '',
  lifeTime: 7
});
// Reactivity init: Captcha widget
const hCaptcha = ref(null);
// Reactivity init: Captcha data
const captchaToken = ref('');
const eKey = ref('');
// Reactivity init: Captcha status
const verified = ref(false);
const expired = ref(false);
const error = ref('');

// Captcha events
function onRendered() {
  showSkeleton.value = false;
}
function onVerify(tokenStr, eKeyStr) {
  verified.value = true;
  captchaToken.value = tokenStr;
  eKey.value = eKeyStr;
  console.log(`Callback token: ${tokenStr}, ekey: ${eKeyStr}`);
}
function onExpire() {
  verified.value = false;
  captchaToken.value = null;
  eKey.value = null;
  expired.value = true;
  console.log('Expired');
}
function onChallengeExpire() {
  verified.value = false;
  captchaToken.value = null;
  eKey.value = null;
  expired.value = true;
  console.log('Challenge expired');
}
function onError(err) {
  captchaToken.value = null;
  eKey.value = null;
  error.value = err;
  console.log(`Error: ${err}`);
}

// Form: Submit
function onSubmit() {
  if (!formData.longLink) {
    Modal.warning({
      title: "提示",
      content: "<p>链接不能为空</p>"
    });
    return;
  }
  if (formData.longLink.length > 8192) {
    Modal.warning({
      title: "提示",
      content: "<p>链接过长</p>"
    });
    return;
  }
  if (!/^(https?|ftp):\/\//.test(formData.longLink)) {
    Modal.warning({
      title: "提示",
      content: `<p>链接必须以 <span style="color:#ed4014;font-weight:bold">http://</span>、<span style="color:#ed4014;font-weight:bold">https://</span> 或 <span style="color:#ed4014;font-weight:bold">ftp://</span> 开头</p>`
    });
    return;
  }
  if (!/(https?|ftp):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/.test(formData.longLink)) {
    Modal.warning({
      title: "提示",
      content: `<p>请输入正确的链接</p>`
    });
    return;
  }
  if (!(formData.lifeTime >= 0 && formData.lifeTime <= 365 && Number.isInteger(formData.lifeTime))) {
    Modal.warning({
      title: "提示",
      content: `<p>请选择正确的有效期</p><p style="color:#ed4014;font-weight:bold">不要篡改数据</p>`
    });
    return;
  }
  if (!verified.value && !expired.value) {
    Modal.warning({
      title: "提示",
      content: "<p>未进行人机验证</p>"
    });
    return;
  }
  if (!verified.value && expired.value) {
    Modal.warning({
      title: "提示",
      content: "<p>人机验证已过期，请重新验证</p>"
    });
    return;
  }

  loadingStatus.value = true;

  addLinkApi(formData.longLink, formData.lifeTime, captchaToken.value)
    .then((response) => {
      let msg, msgType;

      if (response.code === 2001) {
        resultStyle.value = 'warning_style';
        msg = '已存在相同记录';
        msgType = 'warning';
      } else {
        resultStyle.value = 'success_style';
        msg = '生成完毕';
        msgType = 'success';
      }

      shortLink.value = response.data.shortLink;

      Message[msgType]({
        background: true,
        content: msg
      });
    })
    .catch((error) => {
      console.log(error);
      resultStyle.value = 'error_style'
      shortLink.value = '😅';
      // Message.error({
      //   background: true,
      //   content: '请求出错'
      // });
    }).finally(() => {
      captchaToken.value = '';
      eKey.value = '';
      verified.value = false;
      expired.value = false;
      error.value = '';
      hCaptcha.value.reset();
      loadingStatus.value = false;
    });
}
// Form: Reset
async function onReset() {
  shortLink.value = null;
  resultStyle.value = 'normal_style';
  loadingStatus.value = false;
  formData.longLink = '';
  formData.lifeTime = 0;
  captchaToken.value = '';
  eKey.value = '';
  verified.value = false;
  expired.value = false;
  error.value = '';
  await hCaptcha.value.reset();
  Message.success('已重置');
}

// Copy short link
async function copyURL(link) {
  if (!link || loadingStatus.value) {
    Message.warning({
      background: true,
      content: '生成短链接后再复制'
    });
    return;
  }
  try {
    await navigator.clipboard.writeText(link);
    Message.success({
      background: true,
      content: '复制成功'
    });
  } catch (error) {
    Message.error({
      background: true,
      content: '复制失败'
    });
    console.log(`ErrMsg:\n${error}`);
  }
}
</script>

<style lang="less">
@short-link-font-size: 16px;

.sans_font {
  font-family: 'PingFang SC', 'Microsoft YaHei UI', 'Noto Sans SC', 'Helvetica Neue', Helvetica, Arial;
}

.mono_font {
  font-family: 'Cascadia Code', 'Consolas', 'Fira Code', 'Menlo', 'Courier New';
}

html,
body,
#app,
#layout {
  height: 100%;
}

body {
  background: #f5f7f9 !important;
}

// Layout
#layout {
  justify-content: space-between;

  .layout-content {
    #form_card {
      margin-top: 25px;

      .btn_text {
        vertical-align: middle;
      }

      #captcha_wrapper {
        width: 164px;
        height: 144px;
        position: relative;

        #captcha_skeleton {
          width: 164px;
          height: 144px;
          position: absolute;
        }
      }
    }

    #result_card {
      margin-top: 25px;

      div#short-link_container {
        padding: 12px;
        border-radius: 4px;
        cursor: pointer;

        p {
          line-height: 1;
          height: @short-link-font-size;
          font-size: @short-link-font-size;

        }
      }

      // Link container color style
      .normal_style {
        color: #303133;
        background-color: #ebeef5;
      }

      .success_style {
        color: #67c23a;
        background-color: #f0f9eb;
      }

      .warning_style {
        color: #e6a23c;
        background-color: #fdf6ec;
      }

      .error_style {
        color: #f56c6c;
        background-color: #fef0f0;
      }
    }
  }

  .layout-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
  }
}
</style>
