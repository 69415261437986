import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';

import ViewUIPlus from 'view-ui-plus';

import 'normalize.css/normalize.css';
import 'view-ui-plus/dist/styles/viewuiplus.css';

const app = createApp(App);

app.use(ViewUIPlus).mount('#app');
