import request from '@/utils/httpRequest';

function addLinkApi(link, time, token) {
    return request({
        url: '/link',
        method: 'post',
        data: {
            longLink: link,
            lifeTime: time,
            captchaToken: token
        }
    });
}

export { addLinkApi };
